<template>
  <div>
    <b-row class="d-flex justify-content-start mb-2 px-1">
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input
          :placeholder="$t('common.terms.search')"
          size="lg"
          type="search"
          v-model="searchTerm"
        />
      </b-input-group>
    </b-row>
      <!--  -->
    <b-row v-if="version">
      <b-col
        md="6" sm="12" lg="4" xl="3"
        v-for="(app, index) in filteredApps"
        :key="`app-col-${index}`"
      >
        <component
          :is="app.component"
          v-model="filteredApps[index]"
          :key="`app-card-${index}-${version}`"
          @openAuth="beginAuth(app)"
          @delete="deleteAuth(app)"
          @refresh="refreshAuth(app)"
          @getExternalLink="genExternalLink(app)"
        />
      </b-col>
    </b-row>
     <b-row v-else>
      <b-col
        md="6" sm="12" lg="4" xl="3"
        v-for="num in 4"
        :key="`app-col-${num}`"
      >
        <b-card>
          <div class="d-flex justify-content-between no-wrap w-100">
            <b-skeleton class="w-100" height="35px" type="text"/>
          </div>
          <div class="card-body-height">
            <b-skeleton
              class="mt-2"
              height="38px"
              type="text"
            />
            <b-skeleton
              class="mt-4"
              height="20px"
              width="40%"
              type="text"
            />
            <b-skeleton
              height="20px"
              width="60%"
              type="text"
            />
          </div>
          <div>    
            <hr class="w-100">
            <div class="d-flex justify-content-between">
              <b-skeleton
                type="button"
              />
              <b-skeleton
                type="button"
              />
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow, BInputGroup, BInputGroupPrepend, BFormInput, BCard, BSkeleton, BContainer } from 'bootstrap-vue'
import Apps from '@/layouts/components/ThirdPartyApps/Cards'
import ThirdPartyApps from '@/custom/class/Enum/ThridPartyApps'
import { makeToast } from '@/layouts/components/Popups'
 
export default {
  components: {
    BCol, 
    BRow,
    BInputGroup, 
    BInputGroupPrepend,
    BFormInput,
    BCard,
    BSkeleton,
    BContainer,
    ...Apps,
  },
  data() {
    return {
      apps: null,
      version: 0,
      loading: [],
      searchTerm: ''
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    filteredApps(){
      let search = this.searchTerm

      if (!this.apps) return null;

      return this.apps.filter((el) => {
        return this.$t(el.label).toLowerCase().indexOf(search.toLowerCase()) > -1;
      });
    }
  },
  methods: {
    async init() {
      this.apps = await this.sortString(new ThirdPartyApps().apps, 'label')
      this.fetchAllApps()
    },
    fetchAllApps() {
      this.$store.dispatch('getAllApps', { ...this.$route.params }).then((response) => {
        response.map((value) => {
          this.setCardState(value.enum_app, value)
        })
        this.version++
      }).catch((err) => {
        console.error(err)
        makeToast({
          title: this.$t("apps.toast.fetch_all.error.title"),
          text: this.$t("apps.toast.fetch_all.error.message"),
          variant: "danger",
          icon: "XIcon",
        })
      })
    },
    beginAuth(item) {
      let transmission = this.$route.params.transmissionID

      // this.$store.dispatch('getEnvVariable', 'API_URL').then((url) => {
      // url = `${API_URL}/v1/${transmission}/transmission/third_party_app/resolve/${item.id}`
      let url = `https://api.fique.online/v1/${transmission}/transmission/third_party_app/resolve/${item.id}`
      this.$store.dispatch('resolveUrl', { url, params: item.params }).then((response) => {
        url = response
        
        if (item.url){
          url = item.url
        }
        var win = window.open(url);
        
        var timer = setInterval(() => {
          if (win.closed) {
            clearInterval(timer);
            this.loadCard(item);
          }
        }, 500);
      })
    },
    loadCard(item) {
      this.$store.dispatch('getAppById', { item: item.id, ...this.$route.params }).then((response) => {
        if(item.token && (response.token == item.token)){
          makeToast({
            title: this.$t("apps.toast.reload.warning.title"),
            text: this.$t("apps.toast.reload.warning.message", { app: this.$t(item.label)}),
            variant: "warning",
            icon: "AlertTriangleIcon",
          })
        } else {
          this.setCardState(item.id, response)
          this.version++
        }
      }).catch(() => {
        makeToast({
          title: this.$t("apps.toast.get_by_id.error.title"),
          text: this.$t("apps.toast.get_by_id.error.message", { app: this.$t(item.label)}),
          variant: "danger",
          icon: "XIcon",
        })
      })
    },
    refreshAuth(item) {
      this.beginAuth(item)
    },
    deleteAuth(item) {
      this.$bvModal.msgBoxConfirm(this.$t("apps.modal.delete.message", { app: this.$t(item.label)}), {
        title: this.$t("apps.modal.delete.title", { app: this.$t(item.label)}),
        size: 'md',
        okVariant: 'warning',
        okTitle: this.$t("common.modal.yes"),
        cancelTitle: this.$t("common.modal.no"),
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(value => {
        if(value) {
          this.$store.dispatch('deleteAppById', { item: item.id, transmissionID:this.$route.params.transmissionID }).then(() => {
            makeToast({
              title: this.$t("apps.toast.delete.success.title"),
              text: this.$t("apps.toast.delete.success.message", { app: this.$t(item.label)}),
              variant: "success",
              icon: "CheckIcon",
            })
            this.setCardState(item.id)
            this.version++
          }).catch(() => {
            makeToast({
              title: this.$t("apps.toast.delete.error.title"),
              text: this.$t("apps.toast.delete.error.message", { app: this.$t(item.label)}),
              variant: "danger",
              icon: "XIcon",
            })
          })
        }
      })
    },
    genExternalLink(item) {
      this.$store.dispatch('getAuthExternalLink', { enum_app: item.id, transmissionID:this.$route.params.transmissionID }).then((response) => {
        console.log(response)
      })
    },
    setCardState(enum_app_id, data = null) {
      let index = this.apps
      this.apps[index]['token'] = data ? data.token : null
      this.apps[index]['valid_until'] = data ? data.valid_until : null
    },
    async sortString(arr, key) {
      arr.sort((a, b) => {
        const nameA = this.$t(a[key]).toUpperCase(); // ignore upper and lowercase
        const nameB = this.$t(b[key]).toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      console.log(arr)
      return arr;
    }
  }
}
</script>

<style lang="scss" scoped>

.card-body-height {
  height: 160px;
}

</style>