export default {
  CardGoogleSheets:     () => import("./GoogleSheets.vue"),
  CardGoogle:           () => import("./Google.vue"),
  CardFacebook:         () => import("./Facebook.vue"),
  CardWix:              () => import("./Wix.vue"),
  CardMercadoLivre:     () => import("./MercadoLivre.vue"),
  CardContaAzul:        () => import("./ContaAzul.vue"),
  CardTodoist:          () => import("./Todoist.vue"),
  CardShopify:          () => import("./Shopify.vue"),
  CardThomsonReuters:   () => import("./ThomsonReuters.vue"),
  CardLinkedin:         () => import("./Linkedin.vue"),
  CardSap:              () => import("./SAP.vue"),
  CardTiktok:           () => import("./Tiktok.vue"),
  CardIfood:            () => import("./Ifood.vue"),
  CardMicrosoftPowerBi:        () => import("./MicrosoftPowerBi.vue"),
}