export default class EnumThirdPartyApps {
  constructor(){
    this.apps = [
      {
        id: 1,
        label: "enum.third_party_app.google_sheet",
        resolve: "resolveGoogle",
        component: "card-google-sheets",
        url : undefined
      },
      {
        id: 2,
        label: "enum.third_party_app.facebook",
        resolve: "resolveFacebook",
        component: "card-facebook",
        url : undefined
      },
      {
        id: 3,
        identifier: "conta-azul",
        label: "enum.third_party_app.conta_azul",
        resolve: "resolveContaAzul",
        component: "card-conta-azul",
        url : undefined,
      },
      {
        id: 4,
        identifier: "todoist",
        label: "enum.third_party_app.todoist",
        resolve: "resolveTodoIst",
        component: "card-todoist",
        url : undefined,
      },
      {
        id: 5,
        identifier: "wix",
        label: "enum.third_party_app.wix",
        resolve: "resolveWix",
        component: "card-wix",
        url : "https://www.wix.com/market?appMarketParams=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoie1wiYXBwRGVmSWRcIjpcIjc3NTNkNWJlLWUwOWMtNGM2Zi1iZmZiLTAyNzNlZDI0NjcxN1wiLFwiYXBwdXJpXCI6XCJodHRwczovL2luc3RhbmNlLmZpcXVlLm9ubGluZTo5MDkwL3dpeC9zdGFydFwiLFwicm91dGVcIjpcInRlc3RBcHBcIixcInZlcnNpb25cIjpcImxhdGVzdFwifSIsImlhdCI6MTY2ODYxOTQ2OH0.HM2PvKPrtPhWFX-tCBIt6lPAnSqCtMXOW3nw6kiB5VA"
      },
      {
        id: 6,
        identifier: "mercado-livre",
        label: "enum.third_party_app.mercado_livre",
        resolve: "resolveMercadoLivre",
        component: "card-mercado-livre",
        url : undefined
      },
      {
        id: 7,
        identifier: "shopify",
        label: "enum.third_party_app.shopify",
        resolve: "resolveShopify",
        component: "card-shopify",
        url : undefined,
        params: {
          shop: undefined
        }
      },
      {
        id: 8,
        label: "enum.third_party_app.google",
        resolve: "resolveGoogle",
        component: "card-google",
        url : undefined
      },
      // {
      //   id: 9,
      //   label: "enum.third_party_app.ifood",
      //   resolve: "resolveIfood",
      //   component: "card-ifood",
      //   url : undefined
      // },
      {
        id: 10,
        label: "enum.third_party_app.linkedin",
        resolve: "resolveLinkedin",
        component: "card-linkedin",
        url : undefined
      },
      {
        id: 11,
        label: "enum.third_party_app.microsof-power-bi",
        resolve: "resolveMicrosoftPowerBi",
        component: "card-microsoft-power-bi",
        url : undefined
      },
      {
        id: 12,
        label: "enum.third_party_app.sap",
        resolve: "resolveSap",
        component: "card-sap",
        url : undefined
      },
      {
        id: 13,
        label: "enum.third_party_app.thomson_reuters",
        resolve: "resolveThomsonReuters",
        component: "card-thomson-reuters",
        url : undefined
      },
      {
        id: 14,
        label: "enum.third_party_app.tiktok",
        resolve: "resolveTiktok",
        component: "card-tiktok",
        url : undefined
      }
    ]
  }  
}